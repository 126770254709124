.ant-btn-primary.add-new-printer,
.ant-btn-primary.add-new-printer:hover,
.ant-btn-primary.add-new-printer:focus {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.site-card-wrapper {
  padding: 30px;
}
